import * as Sentry from '@sentry/react'
import Button from 'components/Button'
import Modal from 'components/Modal'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, {
  createContext,
  FC,
  Fragment,
  useContext,
  useEffect,
  useState,
} from 'react'
import toast from 'react-hot-toast'
import { FormOptions, TinaCMS, TinaProvider, useForm, usePlugin } from 'tinacms'

const initialValues: { content: any[]; setIsSubmitting: any } = {
  content: null,
  setIsSubmitting: null,
}

export const TinaContext = createContext(initialValues)

export const useTinaContext = () => useContext(TinaContext)

const MarkdownEditor = dynamic(() => import('../components/MarkdownEditor'))

export const TinaContextProvider: FC<{ content: any[] }> = ({
  children,
  content,
}) => {
  const [cms] = useState(() => new TinaCMS({ sidebar: true, toolbar: true }))
  const { query } = useRouter()

  const [isSubmitting, setIsSubmitting] = useState(null)

  useEffect(() => {
    if (query.edit === '1') {
      cms.toggle()
    }
  }, [query])

  return (
    <TinaProvider cms={cms}>
      <TinaContext.Provider value={{ setIsSubmitting, content }}>
        {children}
        {query.edit === '1' && <MarkdownEditor cms={cms} />}
        {isSubmitting && (
          <SubmitModal id={isSubmitting.id} values={isSubmitting.values} />
        )}
      </TinaContext.Provider>
    </TinaProvider>
  )
}

export const useTinaForm = (
  options: Omit<FormOptions<any, any>, 'onSubmit'>
) => {
  const { setIsSubmitting, content } = useTinaContext()

  const existingContent = content?.find((c) => c.tinaId === options.id)

  const tinaForm = useForm({
    ...options,

    onSubmit: (values) => setIsSubmitting({ id: options.id, values }),
    initialValues: existingContent ? JSON.parse(existingContent.content) : null,
  })

  usePlugin(tinaForm[1])

  return tinaForm
}

const SubmitModal: FC<{ values: any; id: string }> = ({ values, id }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { setIsSubmitting } = useTinaContext()

  const handleSubmit = async () => {
    setIsLoading(true)

    try {
      const res = await fetch(
        `${process.env.NEXT_PUBLIC_BASE_URL}/api/content`,
        {
          method: 'POST',
          body: JSON.stringify({ content: values, id, email, password }),
        }
      ).then((res) => res.json())

      if (res.success) {
        setIsSubmitting(null)
        toast.success('Änderungen gespeichert')
      } else {
        toast.error(`Es gab ein Problem: ${res.error}`)
      }
    } catch (error) {
      toast.error(`Es gab ein Problem ${error?.message}`)
      Sentry.captureException(error)
    }

    setIsLoading(false)
  }

  return (
    <Modal
      cancelText='Abbrechen'
      onClose={() => setIsSubmitting(null)}
      title='Änderungen speichern'>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}>
        <div className='my-2'>
          <label htmlFor='email'>E-mail</label>
          <input
            className='block w-full border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm focus:ring-primary-500 focus:border-primary-500'
            id='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type='email'
            required
          />
        </div>
        <div className='my-2'>
          <label>Passwort</label>
          <input
            className='block w-full border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm focus:ring-primary-500 focus:border-primary-500'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type='password'
            required
          />
        </div>
        <Button
          disabled={!email || !password}
          isLoading={isLoading}
          className='w-full my-4'
          type='submit'>
          Speichern
        </Button>
      </form>
    </Modal>
  )
}

export const renderWhiteSpaces = (content: string) => {
  if (!content) return ''

  return content.split('\n').map((item, key) => {
    return (
      <Fragment key={key}>
        {item}
        <br />
      </Fragment>
    )
  })
}
