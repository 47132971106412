import useTranslation from 'next-translate/useTranslation'
import React, { FC } from 'react'
import { referralItems } from '../lib/content'

const Referrals: FC<Props> = ({}) => {
  const { t } = useTranslation('common')
  return (
    <section className='flex flex-col'>
      <p className='w-full text-4xl font-bold'>{t('referralHeader')}</p>
      <div className='flex flex-wrap items-center justify-around'>
        {referralItems.map(({ alt, height, href, image, width }) =>
          href ? (
            <a
              className='p-4 opacity-50'
              key={alt}
              target='_blank'
              rel='noreferrer'
              href={href}>
              <span className='sr-only'>{alt}</span>
              <img
                src={`/newspaper${image}`}
                width={width}
                height={height}
                alt={alt}
              />
            </a>
          ) : (
            <div key={alt} className='p-4 opacity-50'>
              <span className='sr-only'>{alt}</span>
              <img
                src={`/newspaper${image}`}
                width={width}
                height={height}
                alt={alt}
              />
            </div>
          )
        )}
      </div>
    </section>
  )
}

export default Referrals

interface Props {}
