import { renderWhiteSpaces, useTinaForm } from 'lib/tina'
import { FAQPageJsonLd } from 'next-seo'
import useTranslation from 'next-translate/useTranslation'
import React, { FC, ReactNode, useState } from 'react'

export const questions: {
  questionName: string
  acceptedAnswerText: string
  htmlAnswer?: ReactNode
}[] = [
  {
    questionName: 'Wie erreiche ich euch bei dringenden Fragen?',
    acceptedAnswerText:
      'Die meisten Fragen werden dir hier direkt auf unserer Webseite beantwortet. Für darüber hinausgehende Fragen steht dir unser Emailsupport zur Verfügung. Anfragen werden üblicherweise binnen 24 Stunden beantwortet. Unseren telefonischen Kundenservice (nur für zeitkritische Anliegen rund um deinen Befund) erreichst du täglich zwischen 10 und 13 Uhr unter der Rufnummer 040 2383 0500. Bitte beachte, dass wir aufgrund unserer günstigen Preise keine telefonische Beratung geben können und auch nur solche Fragen beantworten, die nicht schon auf unserer Webseite beantwortet wurden.',
  },
  {
    questionName: 'Wann und wie erhalte ich das Ergebnis?',
    acceptedAnswerText:
      'Das Ergebnis erhältst du als SMS mit einem Link. Dieser öffnet unsere Webseite und zeigt dir dort dann das Ergebnis an. Dort kannst du dann auch den Befund als PDF in Deutsch und Englisch laden. Diese Seite erreichst du auch über den Link, den du zum Test erhalten und mit dem du dich verifiziert hast. Den Link kannst du jederzeit öffnen und erhältst nach dem Test dann Informationen, ob das Ergebnis schon vorliegt und wie deine Probennummer lautet. Die Ergebnisse für den Schnelltest liegen in der Regel nach ca. 30 Minuten vor. Unsere PCR-Tests werden in der Regel innerhalb von 24-36 Stunden befundet. Aufgrund verschiedener Umstände kann es im Einzelfall auch schon einmal zu Verzögerungen kommen. Dies kommt beispielsweise vor, wenn eine Nachtestung eines positiven Pools vorgenommen werden muss. Generell ist zu sagen, dass wir unsere Ergebnisse zu über 98 % in der oben beschriebenen Zeit ausliefern. Hierbei handelt es sich allerdings nicht um ein Garantieversprechen. Vielmehr handelt es sich – wie bei allen anderen Testanbietenden Deutschlands auch – um durchschnittliche Erfahrungswerte, die aufgrund besonderer Umstände abweichen können und keinen Grund für ein vertragswidriges Verhalten darstellen. Wir bitten deshalb grundsätzlich darum, den Test nicht zu kurzfristig vor dem Flug oder Termin zu buchen. Plane hierfür zur Sicherheit den vollen möglichen Zeitraum laut Bestimmungen des Reiselandes ein. Im positiven Fall werden deine Daten direkt an dein Gesundheitsamt weitergeleitet.',
  },
  {
    questionName: 'Schnelltest neue Regeln seit dem 13.11.2021',
    acceptedAnswerText:
      'Seit dem 13.11.2021 ist der Test wieder für Alle kostenlos.',
  },
  {
    questionName:
      'Wie bekomme ich mein Schnelltest Ergebnis in die Corona Warn App bzw. das EU Zertifikat?',
    acceptedAnswerText:
      'Wenn du einen Termin buchst, bekommst du im Anschluss eine Verifizierungs-SMS an deine Mobilfunknummer zugesendet. Nach klick auf dem Link musst du unsere AGB bestätigen und wählst dann für die Corona Warn App die Option „Namentliche Datenübertragung“ aus. Sobald du dein Ergebnis vorliegen hast, kannst du dieses dann auf der Ergebnis-Seite über den Button „In Corona Warn App öffnen“ exportieren. Deine Corona Warn App öffnet sich und du wirst gefragt, ob du ein „Testzertifikat anfordern“ möchtest. Bestätige dieses und du wirst dann in der Corona Warn App das Schnelltestergebnis als EU-Zertifikat angezeigt bekommen. Damit kannst du dann auf Reisen gehen und erhältst bei Veranstaltungen in der Regel einen schnelleren Zugang. Achtung: PCR Testergebnisse können nicht in die Corona Warn App übertragen werden.',
  },
  {
    questionName:
      'Welches Datum zählt für einen Test? Entnahmedatum oder Befunddatum?',
    acceptedAnswerText:
      'Es zählt immer das Datum, zu dem die Probe abgegeben wurde.',
  },
  {
    questionName: 'Darf ich vor einem PCR Test etwas essen oder trinken?',
    acceptedAnswerText:
      'Da du bei uns gurgelst, ist es sehr wichtig, dass du mindestens 2 Stunden vorher nichts gegessen hast. Auch Alkohol ist unbedingt vorher zu vermeiden. So verhinderst du, dass deine Probe kein Ergebnis bekommst. Leider führen Speisereste nämlich sehr schnell dazu, dass das Labor bei der Probe dein korrektes Ergebnis ermitteln kann.',
  },
  {
    questionName: 'Benötige ich einen Termin?',
    acceptedAnswerText:
      'Bitte buche deinen Termin vorab. Wir möchten das Infektionsrisiko so gering wie möglich halten. Aus diesem Grund vermeiden wir Warteschlangen und gestalten deinen Aufenthalt in der Teststation kurz und einfach. Deinen Termin kannst du vorab auf unserer Webseite buchen – bei freien Zeitslots auch noch recht spontan auf deinem Handy. ',
  },
  {
    questionName: 'Wieso benötigte ich zwingend ein Smartphone?',
    acceptedAnswerText:
      'Nach deiner Buchung erhältst du von uns eine SMS von „Freepass“. In dieser befindet sich ein Link, den du bestätigen musst. So stellen wir sicher, dass es auch wirklich deine Telefonnummer ist. Das ist wichtig für den Fall eines positiven Befundes, da so das Gesundheitsamt direkt mit dir in Kontakt treten kann.',
  },
  {
    questionName: 'Wann arbeiten die Labore?',
    acceptedAnswerText:
      'Unsere Labore arbeiten selbstverständlich auch am Wochenende und an Feiertagen.',
  },
  {
    questionName: 'An welchen Standorten kann ich mich testen lassen?',
    acceptedAnswerText:
      'Alle Standorte und verfügbaren Testzeiten findest du auf unserer Webseite. Wähle deinen gewünschten Standort aus und suche dann nach einem passenden Termin und einer Uhrzeit. Bereits bei der Standortauswahl wird angezeigt, ob wir dort Schnelltests oder PCR-Tests anbieten. ',
  },
  {
    questionName: 'Wie kann ich einen Termin buchen?',
    acceptedAnswerText:
      'Deinen Termin kannst du ganz einfach direkt auf unserer Webseite hier buchen. Dies geht am Laptop, Desktop oder sogar spontan über dein Handy. Hier gibt es die Möglichkeit, deinen kostenlosen Bürgertest als Schnelltest zu buchen oder einen kostenpflichtigen PCR-Test zum Gurgeln zu bestellen. Achte darauf, Pop-Ups in deinem Browser zu aktivieren, den richtigen Standort und Zeitpunkt auszuwählen, deine eingegebenen Daten vor dem Versenden zu prüfen und den AGB / Datenschutzrichtlinien über Klick-Kästchen zuzustimmen. Wenn du öfter einen Schnelltest buchst, kannst du sogar am Ende der Buchung deine Daten speichern und sie bei der nächsten Buchung wieder verwenden. Telefonisch und an den Teststationen vor Ort können wir dich leider nicht einbuchen. Sollten für den gewünschten Zeitraum noch keine Termine zur Verfügung stehen, prüfe einfach in einigen Tagen nochmal den Kalender, da Termine nur für einen gewissen Zeitraum in der Zukunft teilweise freigeschaltet werden.',
  },
  {
    questionName:
      'Ich glaube, ich habe Corona… seid ihr das richtige Testzentrum für mich?',
    acceptedAnswerText:
      'Wenn du Symptome einer Corona-Erkrankung aufweist, kannst du zum Schutz unserer Mitarbeiter*innen und anderer Gäste den Test leider nicht bei uns durchführen lassen. Bitte wende dich hierzu an das Gesundheitsamt, deinen Hausarzt oder die Hotline 116117.',
  },
  {
    questionName: 'Wie funktioniert der Schnelltest/ Antigentest?',
    acceptedAnswerText:
      'Der Test soll für dich so angenehm wie möglich sein. Deswegen nutzen wir nicht den meist unangenehmen tiefen Mund- und Nasenabstrich. Der Abstrich wird im Nasenvorhof durchgeführt (also ganz vorne in der Nase). Das ist kinderleicht und tut niemandem weh. Den Laborbefund erhältst du in Deutscher und Englischer Sprache mit allen erforderlichen Angaben, so dass du sogar damit verreisen kannst.',
  },
  {
    questionName: 'Wie genau ist der Schnelltest/ Antigentest?',
    acceptedAnswerText:
      'Mit einem Schnelltest bekommst du in kurzer Zeit einen Eindruck, ob du gerade hochinfektiös bist. Der Schnelltest ist grundsätzlich geeignet, um dir im Alltag etwas mehr Sicherheit für einen gewissen Zeitraum zu verschaffen. Es kann bei Antigentests leider auch zu einem ungültigen Ergebnis kommen oder sogar einem falsch positiven Ergebnis. Bei einem ungültigen Ergebnis hat das TestKit aus unterschiedlichsten Gründen kein eindeutiges Ergebnis geliefert. Dann musst du leider noch einmal vorbeikommen und einen neuen Test machen. Bei einem positiven Schnelltest-Ergebnis ist eine gewisse Fehlerquote zu beachten. Das Gesundheitsamt übernimmt in diesen Fällen und wird dann eine PCR-Nachtestung durchführen. Erst diese gibt dann wirklich absolute Gewissheit.',
  },
  {
    questionName: 'Bekomme ich ein anerkanntes Ergebnis beim Schnelltest?',
    acceptedAnswerText: `Der Schnelltest/ Antigentest ist staatlich zugelassen und Teil der Teststrategie der Bundesregierung. Den Befund unseres Arztes kannst du nutzen, um diesen beim Frisör, bei der Arbeit etc. vorzulegen. Dabei nutzen wir bei Corona Freepass ausschließlich BfArM-gelistete Produkte mit der höchsten Sensitivität und Spezifität. Jeder von uns eingesetzte Schnelltest wurde vom Paul-Ehrlich-Institut PEI überprüft. Alle Vorsichtsmaßnahmen (AHA-L) sind grundsätzlich unbedingt weiterhin einzuhalten.

    Wir verwenden übrigens einen der Besten Schnellteste: BfArM ID AT321/21 Sensitivität 98,1& Spezifität 99,0%
    `,
  },
  {
    questionName: 'Mein Test ist positiv. Was soll ich jetzt tun?',
    acceptedAnswerText: `Bitte begebe dich umgehend in häusliche Quarantäne. Gemäß des Infektionsschutzgesetzes sind wir verpflichtet, einen positiven Befund beim Gesundheitsamt zu melden. Das Gesundheitsamt wird sich bei dir melden und das weitere Vorgehen mit dir besprechen. Bei Fragen wende dich bitte an die Hotline 116117.

    Aber beachte auch: Das meint nicht, dass du wirklich positiv bist. Erst ein PCR Nachtest wird dir dann Gewissheit geben.`,
  },
  {
    questionName: 'Ist der Gurgel RT-PCR-Test mit Zertifikat und QR-Code?',
    acceptedAnswerText:
      'An diversen Standorten bieten wir dir den günstigsten PCR-Tests Deutschlands. Unser Gurgel RT-PCR-Test ist für Reisen ins Ausland zugelassen. Der Befund wird von unserem Labor als PDF in Deutsch und Englisch, inklusive eines QR-Codes zur Überprüfung der Echtheit, ausgestellt. Auf dem Befund sind deine Daten mit der Nummer des angegebenen Ausweisdokuments, Daten zur entnommenen Probe und Probendatum, die Methode: „SARS-CoV-2-RNA mittels RT-PCR“, das Testergebnis, der CT-Wert, sowie Stempel und Unterschrift des Laborarztes und der QR-Code enthalten. Uns liegen keine Informationen vor, dass unser Test nicht anerkannt wird. Am besten informierst du dich hier vor der Reise über die aktuellen Bedingungen: https://www.auswaertiges-amt.de/de/ReiseUndSicherheit. Achtung: PCR Testergebnisse können nicht in die Corona Warn App übertragen werden.',
  },
  {
    questionName:
      'Was kostet der PCR-Test und werden die Kosten von der Krankenkasse übernommen?',
    acceptedAnswerText:
      'Wir bieten dir Deutschlands günstigsten PCR-Test: Bei uns startest du bereits ab 24,95€. Wir wollen dir den günstigsten Test bieten, weil wir überzeugt sind: Sicherheit durch Tests sollte nicht das Privileg von Besserverdienenden sein. Wir verlangen nur so viel, wie ein Test kosten muss. Der PCR-Test ist nicht grundsätzlich kostenfrei erhältlich. Abrechnungen mit der Krankenkasse oder anderen Stellen führen wir nicht durch. Solltest du die Rechnung durch jemand anderen übernehmen lassen wollen, muss die entsprechende Rechnungsadresse bei der Buchung bereits eingegeben werden, da diese im Nachgang nicht geändert werden kann. ',
  },
  {
    questionName: 'Wie funktioniert der PCR-Test?',
    acceptedAnswerText:
      'Die Entnahme der Probe erfolgt durch einfaches Gurgeln für 20 Sekunden mit einer Kochsalzlösung. Danach wird die Probe durch unser zugelassenes Labor im RT-PCR-Kreuz-Pooling Verfahren durchgeführt. Dieses zählt als das sicherste Verfahren in der Analysetechnik zu Covid-19. Es handelt sich hierbei nicht um einen Schnelltest.',
  },
  {
    questionName: 'Wie genau ist der PCR-Test?',
    acceptedAnswerText:
      'Ein PCR-Test erfolgt mittels einer Laboranalyse durch einen Arzt und ist im Gegensatz zu den Antigentests, die auch Schnelltests genannt werden, kein medizinisches Produkt, das auf der Liste der BfArM oder PEI gelistet ist. Stattdessen handelt es sich bei einem PCR-Test um einen labortechnischen Analyse-Prozess. PCR steht dabei für Polymerase-Ketten-Reaktion (Polymerase Chain Reaction) und ist eine Methode, um Erbsubstanz, in diesem Fall des SARS-CoV-2-Virus, durch dessen Vervielfältigung im Labor nachzuweisen. Dazu werden Reagenzien eingesetzt, Substanzen, die zum Nachweis benötigt werden. Ein PCR-Test ist niemals ein gelistetes Pharma-Produkt, sondern bezeichnet die Tätigkeit eines Labor-Arztes. Sicher ist bekanntlich nie etwas zu 100%. Durch unser RT-PCR-Kreuz-Pooling Verfahren können wir jedoch eventuelle Falschergebnisse auf eine Wahrscheinlichkeit von 1:1.500.000 reduzieren. PCR-Tests haben die höchste Sensitivität und erbringen den bestmöglichen Nachweis einer Corona-Infektion. Zahlreiche Studien haben belegt, dass die Kochsalzlösung die gleiche Empfindlichkeit aufweist, wie ein Abstrich mit Wattestäbchen.',
  },
  {
    questionName: 'Wie kann ich einen kostenpflichtigen Test bezahlen?',
    acceptedAnswerText:
      'Auf Grund von Hygieneschutzmaßnahmen ist eine Zahlung nur vorab online möglich. Vor Ort können wir leider keine Barzahlung annehmen. Aktuell ist die Zahlung über Kreditkarte, GiroPay und SOFORT möglich.',
  },
  {
    questionName: 'Kann ich einen Termin umbuchen oder stornieren?',
    acceptedAnswerText:
      'Wir möchten dich gern auf unsere AGB aufmerksam machen, denen du bei der Buchung zugestimmt hast. Selbstverständlich hast du ein 14tägiges Widerrufsrecht. Sollte die Frist verstrichen sein, so bieten wir dir aus Kulanz eine kostenlose Umbuchung deiner Bestellung auf einen Termin der gleichen Preisklasse an. Schreibe uns einfach an support@coronafreepass.de deinen neuen Wunschtermin. Unsere AGB findest du hier https://coronafreepass.de/agb',
  },
  {
    questionName: 'Kann ich Kinder und Begleitpersonen zum Testen mitbringen?',
    acceptedAnswerText:
      'Wir möchten dich darauf hinweisen, dass wir unsere Tests für Personen ab 5 Jahre anbieten. Unsere Erfahrung mit den bisherigen Tests hat gezeigt, dass Kids unter 5 Jahren beim PCR Test Schwierigkeiten mit der Durchführung des Gurgelns haben. 20 Sekunden werden da schnell sehr lang. Leider brauchen wir bei diesen Proband*innen mehr als einen Testbecher, da die Probe oft nicht ausreichend gegurgelt wurde oder etwas daneben ging. Mit einem Kind im Alter zwischen 5 und 8 Jahren solltest du unbedingt vorher das 20sekündige Gurgeln üben, denn wenn kleinere Kids damit überfordert sind, können wir im Nachgang den Test natürlich nicht stornieren und das Geld zurückzahlen, da unsere Leistung erbracht wurde. Für unsere Schnelltests/ Antigentests ist das Mindestalter von 5 Jahren ebenfalls erforderlich, um sicherzustellen, dass wir das Stäbchen sicher und korrekt einführen und den Abstrich vornehmen können. Soll der Test nur bei einem Kind vorgenommen werden, muss eine berechtigte Begleitperson mit anwesend sein. Die Daten der Begleitperson sind bei der Buchung des Kindes mit anzugeben. ',
  },
  {
    questionName: 'Wann folgen Termine für den kommenden Monat?',
    acceptedAnswerText:
      'Unsere Termine für den kommenden Monat werden zum Ende des vorigen Monats geplant und stehen dann online auf der Buchungsplattform zur Verfügung. Alle unsere aktuell buchbaren Termine sind online gestellt. Für alle buchbaren Tage ist es selbstverständlich geplant, auch die Testergebnisse im üblichen Zeitrahmen zur Verfügung zu stellen. Wenn an bestimmten Daten/ Zeiten der Slot ausgegraut ist, ist er entweder ausgebucht oder nicht buchbar. Hier können wir dir dann keine Termine anbieten.  ',
  },
]

const Question = ({ questionName, acceptedAnswerText }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div key={questionName} className='pt-3'>
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className='w-full text-left text-gray-400 '>
        <dt className='flex items-start justify-between text-lg'>
          <span className='font-medium text-gray-900'>{questionName}</span>
          <span className='flex items-center ml-6'>
            <svg
              className={`w-6 h-6 transform ${isOpen ? '-rotate-180' : ''} `}
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              aria-hidden='true'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M19 9l-7 7-7-7'
              />
            </svg>
          </span>
        </dt>
      </button>
      {isOpen && (
        <dd className='pr-12 mt-2'>
          <p className='text-base text-gray-700'>
            {renderWhiteSpaces(acceptedAnswerText)}
          </p>
        </dd>
      )}
    </div>
  )
}

const FAQ: FC<Props> = ({}) => {
  const { t } = useTranslation('common')
  const [values] = useTinaForm({
    id: '1',
    label: 'FAQ',
    fields: [
      {
        label: 'Items',
        name: 'items',
        component: 'group-list',
        itemProps: (item) => ({
          key: item.id,
          label: item.question ? item.question : 'Leere Frage',
        }),
        fields: [
          {
            label: 'Frage',
            name: 'question',
            component: 'text',
          },
          { label: 'Antwort', name: 'answer', component: 'textarea' },
        ],
      },
    ],
  })

  return (
    <div id='faq' className='py-12 sm:py-16'>
      <FAQPageJsonLd
        mainEntity={values?.items?.map((item) => ({
          questionName: item.question,
          acceptedAnswerText: item.answer,
        }))}
      />
      <div className='divide-y-2 divide-primary-500'>
        <h2 className='text-3xl font-extrabold text-gray-900 sm:text-4xl'>
          {t('faqHeader')}
        </h2>
        <dl className='max-w-full mt-6 space-y-6 prose divide-y divide-gray-200'>
          {values?.items?.map(({ question, answer }) => (
            <Question
              questionName={question}
              acceptedAnswerText={answer}
              key={question}
            />
          ))}
        </dl>
      </div>
    </div>
  )
}

export default FAQ

interface Props {}
