import { renderWhiteSpaces, useTinaForm } from 'lib/tina'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import React, { FC } from 'react'
import { menuItems } from '../lib/content'

const Blog: FC<Props> = ({}) => {
  const { t } = useTranslation('common')
  const [values] = useTinaForm({
    id: '3',
    label: 'Blog',
    fields: [
      {
        name: 'pcr',
        label: 'PCR',
        component: 'group',
        fields: [
          {
            label: 'Titel',
            name: 'title',
            component: 'text',
          },
          {
            label: 'Beschreibung',
            name: 'description',
            component: 'textarea',
          },
        ],
      },
      {
        name: 'antigen',
        label: 'Antigen',
        component: 'group',
        fields: [
          {
            label: 'Titel',
            name: 'title',
            component: 'text',
          },
          {
            label: 'Beschreibung',
            name: 'description',
            component: 'textarea',
          },
        ],
      },
      {
        name: 'firmen',
        label: 'Firmen',
        component: 'group',
        fields: [
          {
            label: 'Titel',
            name: 'title',
            component: 'text',
          },
          {
            label: 'Beschreibung',
            name: 'description',
            component: 'textarea',
          },
        ],
      },
    ],
  })

  return (
    <div className='relative pt-16 pb-4 lg:pt-24'>
      <div className='relative '>
        <div className='text-left'>
          <h2 className='text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
            {t('blogHeader')}
          </h2>
        </div>
        <div className='grid max-w-lg gap-5 mx-auto mt-12 lg:max-w-none lg:grid-cols-3'>
          {menuItems(t)
            .filter((t) => t.image)
            .map(({ description, href, image, alt, label }) => {
              const content = values[href.replace('/', '')]
              return (
                <Link key={alt} href={href}>
                  <a className='flex flex-col overflow-hidden transition duration-500 rounded-lg shadow-md hover:shadow-lg'>
                    <div className='flex-shrink-0'>
                      <img src={image} alt={alt} />
                    </div>
                    <div className='flex flex-col justify-between flex-1 p-6 bg-white'>
                      <div className='flex-1'>
                        <p className='text-xl font-semibold text-gray-900'>
                          {content?.title}
                        </p>
                        <p className='mt-3 text-base text-gray-500'>
                          {renderWhiteSpaces(content?.description)}
                        </p>
                      </div>
                      <p className='mt-4 font-medium text-primary-500'>
                        {t('blogCTA')}
                      </p>
                    </div>
                  </a>
                </Link>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default Blog

interface Props {}
