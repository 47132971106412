import { event } from 'lib/analytics'
import { renderWhiteSpaces, useTinaForm } from 'lib/tina'
import { TestLocation } from 'lib/types'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import Button from './Button'
import PaymentProviders from './PaymentProviders'

const GeneralCopy = () => {
  const [values] = useTinaForm({
    id: '2',
    label: 'Hero',
    fields: [
      {
        name: 'title',
        label: 'Titel',
        component: 'textarea',
      },
      {
        name: 'description',
        label: 'Beschreibung',
        component: 'html',
      },
    ],
  })

  return (
    <>
      <h1 className='block text-3xl font-extrabold tracking-tight text-primary-500 xl:inline sm:text-4xl md:text-5xl lg:text-4xl xl:text-5xl'>
        {renderWhiteSpaces(values.title)}
      </h1>

      <div
        dangerouslySetInnerHTML={{ __html: values.description }}
        className='max-w-md mx-auto mt-3 text-lg prose text-gray-600 sm:text-xl md:mt-5 md:max-w-3xl'></div>
    </>
  )
}

const LocationCopy: FC<Props> = ({ location }) => {
  const [values] = useTinaForm({
    id: location.region_name,
    label: 'Hero',
    fields: [
      {
        name: 'title',
        label: 'Titel',
        component: 'textarea',
      },
      {
        name: 'subTitle',
        label: 'Untertitel',
        component: 'textarea',
      },
      {
        name: 'description',
        label: 'Beschreibung',
        component: 'html',
      },
    ],
  })

  return (
    <>
      <h1 className='block text-3xl font-extrabold tracking-tight text-primary-500 xl:inline sm:text-4xl md:text-5xl lg:text-4xl xl:text-5xl'>
        {renderWhiteSpaces(values.title)}
      </h1>
      <div>
        <h2 className='my-4 text-2xl font-bold'>{values.subTitle}</h2>
        <div
          className='max-w-md mx-auto mt-3 text-lg prose text-gray-600 sm:text-xl md:mt-5 md:max-w-3xl'
          dangerouslySetInnerHTML={{ __html: values.description }}
        />
      </div>
    </>
  )
}

const Hero: FC<Props> = ({ location }) => {
  const router = useRouter()
  const { t } = useTranslation('common')

  return (
    <div className='pb-8 sm:pb-12 lg:pb-12 '>
      <div className='pt-8 overflow-hidden text-center bg-gray-200 lg:relative lg:py-4 lg:text-left lg:min-h-[700px]'>
        <div className='max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24'>
          <div>
            {location ? <LocationCopy location={location} /> : <GeneralCopy />}
            <div className='flex flex-col items-center mt-4 lg:items-start'>
              <Link
                href={
                  location
                    ? `/buchen?region=${location.region_name}`
                    : router.query.code
                    ? `/buchen?code=${router.query.code}`
                    : '/buchen'
                }>
                <a className='flex mb-6 '>
                  {location && (
                    <div className='p-4 text-lg font-semibold text-white uppercase rounded-l-lg shadow-lg bg-secondary-500 '>
                      {t('from')}{' '}
                      {(location.lowestPrice / 100)
                        .toFixed(2)
                        .replace('.', ',')}
                      €
                    </div>
                  )}
                  <Button
                    onClick={() =>
                      event({ name: 'booking_session_init', location: 'hero' })
                    }
                    className={`w-full md:w-auto ${
                      location ? 'rounded-l-none' : ''
                    }`}
                    Icon={
                      <svg
                        className='w-5 h-5'
                        fill='none'
                        stroke='currentColor'
                        viewBox='0 0 24 24'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                          d='M14 5l7 7m0 0l-7 7m7-7H3'></path>
                      </svg>
                    }>
                    {t('getTested')}
                  </Button>
                </a>
              </Link>
              <PaymentProviders />
            </div>
          </div>
        </div>

        <div className='mt-10 lg:block lg:mt-0 sm:mx-auto sm:max-w-3xl sm:px-6'>
          <div className='flex flex-col p-2 lg:w-1/2 sm:relative lg:absolute lg:inset-y-0 lg:right-0'>
            <img
              className='mt-4 rounded-lg'
              src='/Anzeigecorona.png'
              alt='Woman with Mask'
            />
            <div className='flex items-center justify-around'>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://apps.apple.com/de/app/corona-freepass/id1536751699'>
                <img
                  style={{ height: 100, marginRight: 10 }}
                  src='/app-store-apple.png'
                  alt='Apple Store Link'
                />
              </a>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://play.google.com/store/apps/details?id=de.coronafreepass.android'>
                <img
                  style={{ height: 100 }}
                  src='/googleplay-store.png'
                  alt='Google Play Store Link'
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero

interface Props {
  location?: { lowestPrice: number } & TestLocation
}
