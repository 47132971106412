import React, { FC } from 'react'

const logos = [
  {
    src: '/paypal.svg',
    alt: 'Paypal',
  },
  {
    src: '/masterCard.svg',
    alt: 'MasterCard',
  },
  {
    src: '/visa.svg',
    alt: 'VISA',
  },
  {
    src: '/americanExpress.svg',
    alt: 'American Express',
  },
  // {
  //   src: '/sofort.png',
  //   alt: 'SOFORT Überweisung',
  // },
  {
    src: '/giropay.svg',
    alt: 'Giropay',
  },
  // {
  //   src: '/maestro.svg',
  //   alt: 'Maestro',
  // },
]

const PaymentProviders: FC<Props> = ({ className }) => {
  return (
    <div
      className={`flex flex-row flex-wrap items-center justify-center w-full lg:justify-start ${className}`}>
      {logos.map(({ alt, src }) => (
        <img
          key={alt}
          className='m-2 mx-3'
          alt={alt}
          src={`/paymentLogos${src}`}
          style={{ height: 30 }}
        />
      ))}
    </div>
  )
}

export default PaymentProviders

interface Props {
  className?: string
}
